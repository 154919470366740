<template>
  <Layout>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" dismissible variant="success">
        {{ alertData }}
      </b-alert>
    </div>
    <PageHeader :items="items" :title="title" />

    <div v-if="isEdit == false" class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <h6 class="col-md-2" style="margin-bottom: 23px;">Excel Upload:</h6>
            <div
              class="form-check form-switch form-switch-md col-md-3"
              dir="ltr"
            >
              <input
                id="customSwitchsizemd"
                v-model="this.excelUpload"
                class="form-check-input"
                type="checkbox"
                @change="isExcelUpload()"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">
              <label>Select Restaurant Brand* </label>
              <multiselect
                v-model="posts.restaurantID"
                :class="{
                  'is-invalid': submitted && $v.posts.restaurantID.$error
                }"
                :disabled="disableRest"
                :options="restaurants"
                label="name"
                track-by="restID"
                @input="onChangeRestBrand()"
              >
              </multiselect>
              <div
                v-if="submitted && $v.posts.restaurantID.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.posts.restaurantID.required"
                  >This value is required.</span
                >
              </div>
            </div>
            <div class="mb-3 col-md-6 row">
              <div class="col-md-6">
                <label class="form-label" for="formrow-firstname-input"
                  >Select Restaurant Branches *</label
                ><br />
                <button
                  :disabled="disabled"
                  class="btn btn-themeOrange  w-md waves-effect waves-light "
                  type="button"
                  @click="openModal()"
                >
                  Restaurant Branches
                </button>
              </div>

              <p
                :class="{
                  'is-invalid': submitted && $v.selected.$error
                }"
                class="col-md-6"
              >
                Selected branches:<br />
                <!-- {{selected}} -->
                {{ selected.length }}
              </p>
              <div
                v-if="submitted && $v.selected.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.selected.required"
                  >This value is required.</span
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">
              <label> Cuisine Category *</label>
              <multiselect
                v-model="posts.categoryID"
                :class="{
                  'is-invalid': submitted && $v.posts.categoryID.$error
                }"
                :disabled="disabled"
                :options="categoryArr"
                label="categoryName"
                track-by="restCategoryID"
              ></multiselect>
              <div
                v-if="submitted && $v.posts.categoryID.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.posts.categoryID.required"
                  >This value is required.</span
                >
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-2 mx-2"> Select Excel: </label>
              <div class="">
                Choose File
                <input class="" type="file" @change="handleFileUpload" />
              </div>
              <p style="margin-top: 6px;">
                (<a
                  :href="imgPreUrl + 'CloudKitch_Food_Item_Sample.xlsx'"
                  download
                  >Click here</a
                >
                to download sample excel for import menu)
              </p>
              <!-- <img src="./../../../assets/logo.png" width="20" height="20"> -->
              <!-- accept=".xls,.xlsx" -->
            </div>
          </div>

          <div class=" row" style="float:right;">
            <b-spinner
              v-if="loading"
              class="m-2 col-3"
              role="status"
              variant="primary"
            ></b-spinner>
            <button
              :disabled="excelSubmit"
              class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
              type="button"
              @click="submitExcelUsers()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <h6 v-if="isEdit == false" style="text-align: center;">OR</h6>
    <form method="post" @submit.prevent="submitMenuForm">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label>Select Restaurant Brand* </label>
                  <multiselect
                    v-model="posts.restaurantID"
                    :class="{
                      'is-invalid': submitted && $v.posts.restaurantID.$error
                    }"
                    :disabled="disableRest"
                    :options="restaurants"
                    label="name"
                    track-by="restID"
                    @input="onChangeRestBrand()"
                  >
                  </multiselect>
                  <div
                    v-if="submitted && $v.posts.restaurantID.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.posts.restaurantID.required"
                      >This value is required.</span
                    >
                  </div>
                </div>
                <div class="mb-3 col-md-6 row">
                  <div class="col-md-6">
                    <label class="form-label" for="formrow-firstname-input"
                      >Select Restaurant Branches *</label
                    ><br />
                    <button
                      :disabled="disabled"
                      class="btn btn-themeOrange  w-md waves-effect waves-light "
                      type="button"
                      @click="openModal()"
                    >
                      Restaurant Branches
                    </button>
                  </div>

                  <p
                    :class="{
                      'is-invalid': submitted && $v.selected.$error
                    }"
                    class="col-md-6"
                  >
                    Selected branches:<br />
                    <!-- {{selected}} -->
                    {{ selected.length }}
                  </p>
                  <div
                    v-if="submitted && $v.selected.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.selected.required"
                      >This value is required.</span
                    >
                  </div>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label" for="formrow-menuTitle-input"
                    >Title *</label
                  >
                  <input
                    id="formrow-menuTitle-input"
                    v-model="posts.menuTitle"
                    :class="{
                      'is-invalid': submitted && $v.posts.menuTitle.$error
                    }"
                    :disabled="disabled"
                    class="form-control"
                    name="menuTitle"
                    type="text"
                  />
                  <div
                    v-if="submitted && $v.posts.menuTitle.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.posts.menuTitle.required"
                      >This value is required.</span
                    >
                  </div>
                </div>
                <div class="col-md-6 row">
                  <div class="mb-3 col-md-4">
                    <label class="form-label" for="formrow-restName-input"
                      >Upload Image</label
                    >
                    <div class="file-upload-form">
                      Choose File
                      <input
                        :disabled="disabled"
                        accept="image/*"
                        class="uploadInput"
                        type="file"
                        @change="previewImage"
                      />
                    </div>
                  </div>
                  <div
                    v-if="imageData.length > 0"
                    class="image-preview col-md-3"
                  >
                    <img :src="imageData" class="preview" />
                  </div>
                </div>
              </div>

              <b-modal
                id="modalBranches"
                size="lg"
                title="Restuarant Branches"
                title-class="font-18"
              >
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <h6>Restaurant Brand: {{ this.posts.restaurantID.name }}</h6>
                  <p>
                    <b-button class="me-2" size="sm" @click="selectAllRows"
                      >Select all</b-button
                    >
                    <b-button size="sm" @click="clearSelected"
                      >Clear selected</b-button
                    >
                  </p>

                  <b-table
                    ref="selectableTable"
                    :current-page="currentPage"
                    :fields="fields"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :items="tableData"
                    :per-page="perPage"
                    :select-mode="selectMode"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    bordered
                    hover
                    outlined
                    responsive
                    selectable
                    striped
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(selected)="data">
                      <input
                        v-model="selected"
                        :value="data.item.restBranchID"
                        class="form-check-input"
                        type="checkbox"
                      />
                    </template>

                    <!-- <template #cell(selected)="{rowSelected}">
               
                    <template v-if="rowSelected">
                      <span aria-hidden="true"><i class="far fa-check-square"></i></span>
                      <span class="sr-only">Selected</span>
                    </template>
                    
                    <template v-else>
                      <span aria-hidden="true"><i class="far fa-square"></i></span>
                      <span class="sr-only">Not selected</span>
                    </template>
              </template> -->
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :per-page="perPage"
                          :total-rows="rows"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-modal>
            </div>
            <div class="col-md-12 mb-3">
              <label class="form-label" for="formrow-des-input"
                >Description</label
              >
              <textarea
                v-model="posts.description"
                :disabled="disabled"
                :maxlength="300"
                class="form-control"
                name="textarea"
                rows="3"
              ></textarea>
              <p
                v-if="posts.description"
                :class="{
                  'bg-success': posts.description.length !== 300,
                  'bg-danger': posts.description.length === 300
                }"
                class="badge position-absolute"
                style="right: 19px;"
              >
                {{ posts.description.length }} / 300
              </p>
            </div>
            <div class="row">
              <div class="col-md-3 mb-4">
                <label>Food Type *</label>
                <multiselect
                  v-model="posts.foodType"
                  :class="{
                    'is-invalid': submitted && $v.posts.foodType.$error
                  }"
                  :disabled="disabled"
                  :options="foodTypeArr"
                  :show-labels="false"
                  label="foodTypeName"
                  multiple
                  track-by="typeID"
                ></multiselect>
                <div
                  v-if="submitted && $v.posts.foodType.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.posts.foodType.required"
                    >This value is required.</span
                  >
                </div>
              </div>
              <div class="mb-3 col-md-3">
                <label class="form-label" for="formrow-price-input"
                  >Price *</label
                >
                <div class="input-group">
                  <div class="input-group-text">₹</div>

                  <input
                    id="formrow-price-input"
                    v-model="posts.price"
                    :class="{
                      'is-invalid': submitted && $v.posts.price.$error
                    }"
                    :disabled="disabled"
                    class="form-control"
                    type="number"
                  />
                  <div
                    v-if="submitted && $v.posts.price.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.posts.price.required"
                      >This value is required.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label> Cuisine Category *</label>
                <multiselect
                  v-model="posts.categoryID"
                  :class="{
                    'is-invalid': submitted && $v.posts.categoryID.$error
                  }"
                  :disabled="disabled"
                  :options="categoryArr"
                  label="categoryName"
                  track-by="restCategoryID"
                ></multiselect>
                <div
                  v-if="submitted && $v.posts.categoryID.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.posts.categoryID.required"
                    >This value is required.</span
                  >
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label>Format </label>
                <multiselect
                  v-model="posts.cuisineFormat"
                  :disabled="disabled"
                  :options="cuisineFormatArr"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 mb-3">
                <label>Meal Type </label>
                <multiselect
                  v-model="posts.mealType"
                  :disabled="disabled"
                  :options="mealTypeArr"
                  :show-labels="false"
                  label="name"
                  track-by="mealTypeID"
                ></multiselect>
              </div>

              <!-- && posts.mealType.mealTypeID==3 -->
              <div
                v-if="
                  posts.mealType.mealTypeID == 2 ||
                    posts.mealType.mealTypeID == 3
                "
                class="mb-3 col-md-3"
              >
                <label class="form-label" for="formrow-minQtny-input"
                  >Minimum Order Quantity
                </label>
                <input
                  id="formrow-minQtny-input"
                  v-model="posts.minOrderQnty"
                  :disabled="disabled"
                  class="form-control"
                  name="minQtny"
                  type="number"
                />
              </div>
              <div
                v-if="
                  posts.mealType.mealTypeID == 2 ||
                    posts.mealType.mealTypeID == 3
                "
                class="col-md-3 mb-3"
              >
                <label>Lead Time </label>
                <multiselect
                  v-model="posts.leadType"
                  :disabled="disabled"
                  :options="leadTypeArr"
                  :show-labels="false"
                ></multiselect>
              </div>
              <div class="col-md-3 mb-3">
                <label>Calories</label>
                <div class="form-group">
                  <input
                    v-model="posts.calories"
                    class="form-control"
                    min="0"
                    placeholder="Enter calories"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-md-4">
                <label>Serving Time Slot</label>
                <multiselect
                  v-model="posts.servingTime"
                  :disabled="disabled"
                  :options="servingTime"
                  :show-labels="false"
                  label="serveTimeName"
                  multiple
                  track-by="id"
                ></multiselect>
              </div>
              <div
                v-if="posts.servingTime.find(x => x.id == 0)"
                class="mb-3 col-md-2"
              >
                <label class="form-label" for="formrow-starttime-input"
                  >Start Time*</label
                >
                <input
                  v-model="posts.startTime"
                  class="form-control"
                  type="time"
                  value="13:45:00"
                />
              </div>
              <div
                v-if="posts.servingTime.find(x => x.id == 0)"
                class="mb-3 col-md-2"
              >
                <label class="form-label" for="formrow-endtime-input"
                  >End Time*</label
                >
                <input
                  v-model="posts.endTime"
                  class="form-control"
                  type="time"
                  value="13:45:00"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h6 class="mb-3">Tax Breakup:</h6>
            <div class="row">
              <div class="col-md-4 mb-4">
                <label>Select tax structure*</label>
                <multiselect
                  v-model="taxStructure"
                  :class="{
                    'is-invalid': submitted && $v.taxStructure.$error
                  }"
                  :disabled="disabled"
                  :options="taxStructureTypeArr"
                  label="structureName"
                  track-by="structureType"
                ></multiselect>
                <div
                  v-if="submitted && $v.taxStructure.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.taxStructure.required"
                    >This value is required.</span
                  >
                </div>
              </div>
              <div v-if="taxStructure.structureType == 2" class="col-md-4 mb-3">
                <label> IGST* </label>
                <multiselect
                  v-model="integralTax"
                  :disabled="disabled"
                  :options="integralTaxArr"
                  :show-labels="false"
                  label="integralTaxName"
                  track-by="integralTaxID"
                ></multiselect>
              </div>
              <div v-if="taxStructure.structureType == 1" class="col-md-4 mb-3">
                <label>CSGT + SGST* </label>
                <multiselect
                  v-model="stateTax"
                  :disabled="disabled"
                  :options="stateTaxArr"
                  :show-labels="false"
                  label="stateTaxName"
                  track-by="stateTaxID"
                ></multiselect>
              </div>
              <div class="col-md-4 mb-3">
                <label class="form-label" for="formrow-hsnSac-input"
                  >HSN/SAC *</label
                >
                <input
                  id="formrow-hsnSac-input"
                  v-model="posts.hsnSac"
                  :class="{ 'is-invalid': submitted && $v.posts.hsnSac.$error }"
                  :disabled="disabled"
                  class="form-control"
                  name="hsnSac"
                  type="number"
                />
                <div
                  v-if="submitted && $v.posts.hsnSac.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.posts.hsnSac.required"
                    >This value is required.</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h6 class="mb-3">Add AddOn Items:</h6>
            <div class="inner-repeater mb-4">
              <div class="inner mb-3">
                <div class="row">
                  <label class="col-md-4">AddOn Group :</label>
                  <label class="col-md-3">Selection Type:</label>
                  <label class="col-md-3">Required/Complusary:</label>
                </div>

                <div
                  v-for="(data, index) in menuAddons"
                  :key="data.id"
                  class="inner mb-3 row"
                >
                  <div class="col-md-4 col-8">
                    <multiselect
                      v-model="data.addonGroupID"
                      :disabled="disabled"
                      :options="addonsArr"
                      label="groupName"
                      track-by="addonGroupID"
                    ></multiselect>
                  </div>
                  <div class="col-md-3 col-8">
                    <multiselect
                      v-model="data.selTypeID"
                      :disabled="disabled"
                      :options="selectionTypeArr"
                      label="selName"
                      track-by="selTypeID"
                    ></multiselect>
                  </div>
                  <div class="col-md-3 col-8">
                    <input
                      id="autoSizingCheck2"
                      v-model="data.isRequired"
                      :disabled="disabled"
                      class="form-check-input"
                      type="checkbox"
                      @change="isRequired()"
                    />
                    <label
                      class="form-check-label"
                      for="autoSizingCheck2"
                      style="margin-left: 5px;"
                    >
                      mark as required
                    </label>
                  </div>
                  <div class="col-lg-2 align-self-center d-grid">
                    <input
                      class="btn btn-themeBrown btn-block inner"
                      type="button"
                      value="Delete"
                      @click="deleteItem(index, data.groupID)"
                    />
                  </div>
                </div>
              </div>
              <input
                class="btn btn-themeOrange inner"
                type="button"
                value="Add new item"
                @click="AddItem"
              />
            </div>

            <div class=" row" style="float:right;">
              <b-spinner
                v-if="loading"
                class="m-2 col-3"
                role="status"
                variant="primary"
              ></b-spinner>
              <button
                :disabled="loading"
                class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                type="button"
                @click="isEdit ? updateRestaurantMenu() : submitMenuForm()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- Success circle button with ripple effect -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authHeader } from "../../../helpers/fakebackend/auth-header";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Add Menu",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Multiselect,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Add Menu",
      items: [
        {
          text: "Menu",
          href: "/"
        },
        {
          text: "Add",
          active: true
        }
      ],
      menuAddons: [
        {
          id: 0,
          addonGroupID: "",
          groupID: 0,
          isRequired: "",
          selTypeID: ""
        }
      ],
      selectionTypeArr: [
        { selTypeID: "1", selName: "Select Any 1" },
        { selTypeID: "2", selName: "Select Any 2" },
        { selTypeID: "3", selName: "Mutli Select" }
      ],
      leadTypeArr: ["12 hrs", "24 hrs", "48 hrs", "96 hrs"],

      // cuisineFormatArr:[
      //     { cuisineFormatID: '1', cuisineFormatName: 'Buffet' },
      //     { cuisineFormatID: '2', cuisineFormatName: 'Mini Buffet' },
      //     { cuisineFormatID: '3', cuisineFormatName: 'Platter' },
      //     { cuisineFormatID: '4', cuisineFormatName: 'Lunch Box' },
      //     { cuisineFormatID: '4', cuisineFormatName: 'Finger Food' },
      //     { cuisineFormatID: '4', cuisineFormatName: 'Canapés' },
      //     { cuisineFormatID: '4', cuisineFormatName: 'Live Station / Pop-up' },
      //     { cuisineFormatID: '4', cuisineFormatName: 'Formal Dining' },
      // ],
      cuisineFormatArr: [
        "Buffet",
        "Mini Buffet",
        "Platter",
        "Lunch Box",
        "Finger Food",
        "Canapés",
        "Live Station / Pop-up",
        "Formal Dining"
      ],
      taxStructureTypeArr: [
        { structureType: "1", structureName: "State / Central tax" }
        // { structureType: '2', structureName: 'Integral Tax' },
      ],
      integralTaxArr: [
        { integralTaxID: "0", integralTaxName: "0%" },
        { integralTaxID: "5", integralTaxName: "5%" },
        { integralTaxID: "12", integralTaxName: "12%" },
        { integralTaxID: "18", integralTaxName: "18%" },
        { integralTaxID: "28", integralTaxName: "28%" }
      ],
      stateTaxArr: [
        { stateTaxID: "0", stateTaxName: "CGST(0%) + SGST(0%) = Total(0%)" },
        {
          stateTaxID: "2.5",
          stateTaxName: "CGST(2.5%) + SGST(2.5%) = Total(5%)"
        },
        { stateTaxID: "6", stateTaxName: "CGST(6%) + SGST(6%) = Total(12%)" },
        { stateTaxID: "9", stateTaxName: "CGST(9%) + SGST(9%) = Total(18%)" },
        { stateTaxID: "14", stateTaxName: "CGST(14%) + SGST(14%) = Total(28%)" }
      ],

      imgPreUrl: process.env.VUE_APP_FILE_URL,
      isEdit: false,
      taxStructure: "",
      integralTax: "",
      stateTax: "",
      disabled: true, // all input hidden before selection of rest
      status: "",
      submitted: false,
      foodTypeArr: [],
      mealTypeArr: [],
      servingTime: "",
      excelUpload: "",
      excelSubmit: "",

      imageData: "",
      restaurants: [],
      companyID: 0,
      addonsArr: [],
      addonGroupID: "",
      selectionTypeID: "",
      isRequired: "",
      selectedBranchID: [],
      categoryArr: [],
      showDismissibleAlert: false,
      alertData: "Menu added Successfully!",
      loading: false,
      encodeImage: "",
      dishID: 0,
      disableRest: false,
      selectAll: false,

      // table for branch ////
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectMode: "multi",
      selected: [],
      dishData: "",

      fields: [
        {
          key: "selected",

          sortable: false
        },
        {
          key: "branchName",
          sortable: true
        },
        {
          key: "address",

          sortable: true
        }
      ],

      posts: {
        mealType: "",
        foodType: "",
        menuTitle: null,
        restaurantID: "",
        description: "",
        imageData: "",
        price: "",
        leadType: "",
        minOrderQnty: "",
        categoryID: "",
        cuisineFormat: "",
        servingTime: [],
        startTime: "",
        endTime: "",
        hsnSac: "",
        calories: ""
      }
    };
  },

  validations: {
    posts: {
      restaurantID: {
        required
      },
      foodType: {
        required
      },
      menuTitle: {
        required
      },
      hsnSac: {
        required
      },
      price: {
        required
      },
      categoryID: {
        required
      }
    },
    selected: {
      required
    },
    taxStructure: {
      required
    }
  },
  methods: {
    isExcelUpload() {
      this.excelUpload = !this.excelUpload;
      this.excelSubmit = !this.excelSubmit;
      if (this.excelUpload) {
        this.excelSubmit = false;
      } else {
        this.excelSubmit = true;
      }
    },
    handleFileUpload: function(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        this.file = input.files[0];
      }

      // console.log(this.file);
    },
    submitExcelUsers() {
      if (this.file.length < 0) {
        alert("Please Choose file for upload");
        return;
      } else {
        let formData = new FormData();

        formData.append("file", this.file);
        formData.append("branchIDs", JSON.stringify(this.selected));
        formData.append("restaurantID", this.posts.restaurantID.restID);
        formData.append("categoryID", this.posts.categoryID.restCategoryID);
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);
        let headers = authHeader();
        headers["Content-Type"] = "multipart/form-data";
        this.axios
          .post(this.$loggedRole+"/importRestaurantMenu", formData, {
            headers
          })
          .then(result => {
            this.status = result.data.data;
            this.showDismissibleAlert = true;
            this.loading = false;

            setTimeout(() => {
              this.$router.push({ name: "restaurantMenuTable" });
            }, 2000);
          })
          .catch(error => {
            this.loading = false;

            alert(error.response.data.data);
          });
      }
    },
    getDishesByID(dishID) {
      this.axios.post(this.$loggedRole+"/getDishesByID", { dishID: dishID }).then(result => {
        this.disabled = false;
        //  alert(this.stateTaxArr.stateTaxID.findIndex(x => x.word === 14));
        this.disableRest = true;
        let dishData = result.data.data;
        this.posts.menuTitle = dishData.title;
        this.posts.hsnSac = dishData.hsnSac;
        this.taxStructure = dishData.taxStructure;
        this.taxStructure.structureType = dishData.taxStructureType;
        this.integralTax = dishData.IGST;
        this.stateTax = dishData.SGST;
        this.posts.foodType = dishData.foodTypeArr;
        this.posts.price = dishData.price;
        this.posts.description = dishData.description;
        this.posts.categoryID = dishData.categoryID;
        this.posts.restaurantID = dishData.restaurantName;
        this.posts.minOrderQnty = dishData.minOrderQnty;
        this.imageData = dishData.image;
        this.posts.leadType = dishData.leadTime;
        this.posts.calories = dishData.calories;
        // alert(this.cuisineFormatArr.indexOf("Formal Dining"));
        this.posts.cuisineFormat = this.cuisineFormatArr[
          this.cuisineFormatArr.indexOf(dishData.cuisineFormat)
        ];
        this.posts.servingTime = dishData.dishServingTimeSlot;
        this.posts.startTime = dishData.startTime;
        this.posts.endTime = dishData.endTime;
        this.posts.mealType = dishData.mealTypeArr;
        this.selected.length = dishData.getRestBranchs.length;
        this.selected = dishData.getRestBranchs;

        this.menuAddons = [];
        // console.log(dishData.addonDataArr);
        if (dishData.addonDataArr.length > 0) {
          for (var i = 0; i < dishData.addonDataArr.length; i++) {
            this.menuAddons.push({
              addonGroupID: dishData.addonDataArr[i],
              selTypeID: dishData.addonGroupData[i].selectionType,
              isRequired: dishData.addonDataArr[i].required,
              groupID: dishData.addonDataArr[i].dishAddonID
            });
          }
        } else {
          this.menuAddons = [
            {
              id: 1,
              addonGroupID: "",
              groupID: 0,
              isRequired: "",
              selTypeID: ""
            }
          ];
        }
        // this.posts.servingTime = dishData.dishServingTimeSlot;
        this.onChangeRestBrand();
      });
    },
    submitMenuForm() {
      // alert(this.posts.delTime);

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.scrollTo(0, 0);
        return;
      } else {
        this.loading = true;
        let formData = new FormData();

        formData.append("menuTitle", this.posts.menuTitle);
        formData.append("description", this.posts.description);
        formData.append("price", this.posts.price);
        formData.append("minOrderQnty", this.posts.minOrderQnty);
        formData.append("foodType", JSON.stringify(this.posts.foodType));
        formData.append("mealType", this.posts.mealType.mealTypeID);
        formData.append("calories", this.posts.calories);
        formData.append("addons", JSON.stringify(this.menuAddons));
        formData.append("restaurantID", this.posts.restaurantID.restID);
        formData.append("categoryID", this.posts.categoryID.restCategoryID);
        formData.append("leadTime", this.posts.leadType);
        formData.append("cuisineFormat", this.posts.cuisineFormat);
        formData.append("branchIDs", JSON.stringify(this.selected));
        formData.append("document", this.encodeImage);
        if (this.taxStructure.structureType == 1) {
          formData.append("CGST", this.stateTax.stateTaxID);
          formData.append("IGST", "0");
        } else if (this.taxStructure.structureType == 2) {
          formData.append("IGST", this.integralTax.integralTaxID);
          formData.append("CGST", "0");
        } else {
          formData.append("IGST", this.integralTax.integralTaxID);
          formData.append("CGST", this.stateTax.stateTaxID);
        }
        // formData.append('IGST',this.integralTax.integralTaxID);
        // formData.append('CGST',this.stateTax.stateTaxID);
        formData.append("hsnSac", this.posts.hsnSac);
        formData.append("servingTime", JSON.stringify(this.posts.servingTime));
        formData.append("startTime", this.posts.startTime);
        formData.append("endTime", this.posts.endTime);
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);

        let headers = authHeader();
        headers["Content-Type"] = "multipart/form-data";
        this.axios
          .post(this.$loggedRole+"/addRestaurantBranchMenu", formData, {
            headers
          })
          .then(result => {
            this.status = result.data.data;
            this.showDismissibleAlert = true;
            this.loading = false;

            setTimeout(() => {
              this.$router.push({ name: "restaurantMenuTable" });
            }, 2000);
          })
          .catch(error => {
            this.loading = false;
            //console.error(error.response);
            alert(error.response.data.data);
          });
      }
    },
    foodTypeList() {
      this.axios.get(this.$loggedRole+"/getFoodTypeList").then(result => {
        this.foodTypeArr = result.data.data;
      });
    },
    getCategoryList() {
      //alert(this.posts.restaurantID.restID);
      this.axios
        .post(this.$loggedRole+"/getRestaurantCategoryList", {
          restID: this.posts.restaurantID.restID,
          empTypeID: this.$storageData.profile.empTypeID
        })
        .then(result => {
          this.categoryArr = result.data.data;
        });
    },
    getMealTypeList() {
      this.axios.get(this.$loggedRole+"/getMealTypeList").then(result => {
        this.mealTypeArr = result.data.data;
      });
    },
    getDishServingTime() {
      this.axios.get(this.$loggedRole+"/getDishServingTime").then(result => {
        this.servingTime = result.data.data;
      });
    },
    getRestaurantList() {
      var companyID = this.companyID;
      if (
        this.$storageData.login_type == 2 ||
        this.$storageData.profile.empTypeID == 11
      ) {
        companyID = this.$storageData.profile.companyID;
      }

      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          companyID: companyID,
          restID:
            this.$storageData.login_type == 2 &&
            this.$storageData.profile.empTypeID != 11
              ? this.$storageData.profile.restID
              : 0,
          restBranchID:
            this.$storageData.login_type == 2 &&
            this.$storageData.profile.empTypeID != 11
              ? this.$storageData.profile.restBranchID
              : 0,
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.$storageData.profile.city
        })
        .then(result => {
          this.restaurants = result.data.data;
        });
    },
    getAddonsByRestID(restID) {
      this.axios.post(this.$loggedRole+"/getAddonsByRestID", { restID: restID }).then(result => {
        this.addonsArr = result.data.data;
      });
    },

    previewImage: function(event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = e => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
        this.encodeImage = input.files[0];
      }
    },

    /**
     * Add the phone number in form
     */
    AddItem() {
      this.menuAddons.push({
        addonGroupID: "",
        selTypeID: "",
        isRequired: "",
        groupID: 0
      });
      //console.log(this.menuAddons);
    },
    /**
     * Delete the row from form
     */
    deleteItem(index, groupID) {
      // groupID is dishAddonID from ck_dish_addons
      if (groupID > 0) {
        if (confirm("Are you sure you want to delete this element?")) {
          this.menuAddons.splice(index, 1);
          this.axios.post(this.$loggedRole+"/deleteDishAddons", { groupID: groupID }).then(() => {
            this.showDismissibleAlert = true;
            this.loading = false;
            this.alertData = "Addon Deleted successfully!";
            setTimeout(() => {
              this.showDismissibleAlert = false;
            }, 2000);
          });
        }
      } else {
        this.menuAddons.splice(index, 1);
      }
    },

    onChangeRestBrand() {
      this.selected = [];
      this.disabled = false; // all input fields are unabled
      ///// get branches table data ///////
      this.totalRows = this.items.length;
      this.readRestaurantBranchData();
      this.getCategoryList();
      ////// get addons list ///////////
      let restID = this.posts.restaurantID.restID;
      this.getAddonsByRestID(restID);
    },
    ///// data table methods
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readRestaurantBranchData() {
      let restID = this.posts.restaurantID.restID;
      let flag = this.isEdit == true ? 1 : 0;

      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          flag: flag,
          dishID: sessionStorage.getItem("dishID"),
          restID: restID,
          restBranchID:
            this.$storageData.login_type == 2 &&
            this.$storageData.profile.empTypeID != 11
              ? this.$storageData.profile.restBranchID
              : 0, // this.$storageData.profile.restBranchID,
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID
        })
        .then(response => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          //this.selected = [];
          if (this.selected.length == 0) {
            for (var i = 0; i <= this.tableData.length; i++) {
              if (this.tableData[i].rowSelected == true) {
                this.selected.push(this.tableData[i].restBranchID);
              }
            }
          }
        });
    },
    openModal() {
      //alert();
      this.$root.$emit("bv::show::modal", "modalBranches");
      this.readRestaurantBranchData();
    },

    // onRowSelected(items) {

    //  var foundIndex = this.selected.findIndex(x => x.restBranchID == items.restBranchID);
    //  if(foundIndex>=0 && items.rowSelected==false){
    //    //this.selected[foundIndex] = items;
    //    this.selected.splice(foundIndex,1);
    //  }else{
    //    this.selected.push(items);
    //  }

    //   },
    selectAllRows() {
      this.selected = [];
      // alert();
      if (!this.selectAll) {
        for (let i in this.tableData) {
          this.selected.push(this.tableData[i].restBranchID);
        }
      }
    },
    clearSelected() {
      this.selected = [];
    },

    ////// end of datatable methods ///////////////////////////
    updateRestaurantMenu() {
      // alert(this.posts.delTime);
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.scrollTo(0, 0);
        return;
      } else {
        this.loading = true;
        let formData = new FormData();

        formData.append("menuTitle", this.posts.menuTitle);
        formData.append("dishID", sessionStorage.getItem("dishID"));
        formData.append("description", this.posts.description);
        formData.append("price", this.posts.price);
        formData.append(
          "minOrderQnty",
          this.posts.minOrderQnty ? this.posts.minOrderQnty : 0
        );
        formData.append("foodType", JSON.stringify(this.posts.foodType));
        formData.append("mealType", this.posts.mealType.mealTypeID);
        formData.append("calories", this.posts.calories);
        formData.append("addons", JSON.stringify(this.menuAddons));
        formData.append("restaurantID", this.posts.restaurantID.restID);
        formData.append("categoryID", this.posts.categoryID.restCategoryID);
        formData.append("leadTime", this.posts.leadType);
        formData.append("cuisineFormat", this.posts.cuisineFormat);
        formData.append("branchIDs", JSON.stringify(this.selected));
        formData.append("document", this.encodeImage);
        if (this.taxStructure.structureType == 1) {
          formData.append("CGST", this.stateTax.stateTaxID);
          formData.append("IGST", "0");
        } else if (this.taxStructure.structureType == 2) {
          formData.append("IGST", this.integralTax.integralTaxID);
          formData.append("CGST", "0");
        } else {
          formData.append("IGST", this.integralTax.integralTaxID);
          formData.append("CGST", this.stateTax.stateTaxID);
        }
        // formData.append('IGST',this.integralTax.integralTaxID);
        // formData.append('CGST',this.stateTax.stateTaxID);
        formData.append("hsnSac", this.posts.hsnSac);
        formData.append("servingTime", JSON.stringify(this.posts.servingTime));
        formData.append("startTime", this.posts.startTime);
        formData.append("endTime", this.posts.endTime);
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);

        //e.preventDefault();
        let headers = authHeader();
        headers["Content-Type"] = "multipart/form-data";
        this.axios
          .post(this.$loggedRole+"/updateRestaurantMenu", formData, {
            headers
          })
          .then(result => {
            this.status = result.data.data;

            this.showDismissibleAlert = true;
            this.loading = false;

            setTimeout(() => {
              this.$router.push({ name: "restaurantMenuTable" });
            }, 2000);
          })
          .catch(error => {
            this.loading = false;
            // console.error(error.response);
            alert(error.response.data.data);
          });
      }
    }
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  created() {
    // console.log(this.$route.params);
    this.dishID = this.$route.params.id;
    if (this.dishID) {
      sessionStorage.setItem("dishID", this.dishID); // Save to sessionStorage
    }
    if (this.$route.params.type == "edit") {
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
  },
  mounted() {
    this.getRestaurantList();
    this.foodTypeList();
    //this.getCategoryList();
    this.getMealTypeList();
    this.getDishServingTime();
    if (this.isEdit == true && sessionStorage.getItem("dishID") > 0) {
      this.getDishesByID(sessionStorage.getItem("dishID"));
      // this.isEdit = !this.isEdit;
    }
  },
  middleware: "authentication"
};
</script>

<style lang="scss">
img.preview {
  width: 100px;
  height: 100px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
  object-fit: contain;
  margin-top: 6px;
}

.file-upload-form {
  padding: 5px 10px;
  background: #ececec;
  border: 1px solid #ececec;
  position: relative;
  color: black;
  border-radius: 2px;
  text-align: center;
  float: left;
  cursor: pointer;
}
.uploadInput {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 24px;
  width: 100%;
}
.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}

.multiselect__single {
  width: 75% !important;
}
</style>
